import React, { useContext, useEffect, useState } from 'react';
import { ErrorBoundary, use } from 'react-error-boundary';
import { Box, Button } from '@mui/material';

import {
  Routes,
  Route,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';

import { CurrentUserContext } from '../../context/CurrentUserContext';
import Menu from '../navigation/MobileMenu';
import Feed from '../feed/NewsFeed'
import UserManagement from '../staff/UserManagement';
import EventBrowser from '../events/EventBrowser';
import PersonalData from '../personal-data/PersonalData';
import TimeRequestBrowser from '../time/TimeRequestBrowser';
import TimeReview from '../time/TimeReview';
import Contacts from '../staff/Contacts';
import EventEvaluation from '../event-evalutation/EventEvaluation';
import TimeReport from '../time/TimeReport';
import AccountSettings from '../staff/AccountSettings';
import ForgotPassword from '../../components/login/ForgotPassword';
import Login from '../../components/login/Login';
import NotAuthorized from './NotAuthorizedPage';
import { getLogout } from '../../components/login/logout';
import { setupInterceptors } from '../../services';
import { ErrorFallback } from './ErrorFallback';
import { ExpiredLink } from './ExpiredLink';

function Page() {
  const match = useMatch('/*');
  const navigate = useNavigate();
  const [width, setWidth] = useState('840px');
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const logout = getLogout(setCurrentUser);

  async function handleLogin(user) {
    await setCurrentUser(user);
    navigate('/');
  }

  // Some views need to be full width
  useEffect(() => {
    switch (match.params['*']) {
      case 'timereview':
        setWidth('100%');
        break
      case 'timereport':
        setWidth('100%');
        break
      default:
        setWidth('840px');
    }

  }, [match]);

  useEffect(() => {
    if (currentUser?.account?.isPasswordChangeRequired) {
      navigate('/settings');
    }
  }, [match, currentUser]);

  useEffect(() => {
    setupInterceptors(navigate, logout);
  }, [navigate]);


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: width,
      }}
    >
      <ErrorBoundary onError={error => console.log('Error boundary', error)} FallbackComponent={ErrorFallback}>
        {(localStorage.getItem('currentUser') && currentUser) &&
          <Menu
            variant='mobile'
          />
        }
        <Routes>
          <Route path='/login' element={<Login onLogin={handleLogin} />} />
          <Route path='/resetpassword' element={<ForgotPassword />} />
          <Route path='/not-authorized' element={<NotAuthorized />} />
          <Route path='/expired' element={<ExpiredLink />} />
          <Route path='/error' element={<ErrorFallback />} />
          <Route
            path='*'
            element={
              <PrivateRoute>
                <Routes>
                  <Route exact path='/' element={<Feed />} />
                  <Route path='/feed' element={<Feed />} />
                  <Route path='/usermanagement' element={
                    <ProtectedRoute level={5}>
                      <UserManagement />
                    </ProtectedRoute>
                  } />
                  <Route path='/personaldata' element={<PersonalData />} />
                  <Route path='/contacts' element={<Contacts />} />
                  <Route path='/timemanagement' element={<TimeRequestBrowser />} />
                  <Route path='/events' element={<EventBrowser />} />
                  <Route path='/eventeval' element={<EventEvaluation />} />
                  <Route path='/timereview' element={
                    <ProtectedRoute level={5}>
                      <TimeReview />
                    </ProtectedRoute>
                  } />
                  <Route path='/timereport' element={
                    <ProtectedRoute level={5}>
                      <TimeReport />
                    </ProtectedRoute>
                  } />
                  <Route path='/settings' element={<AccountSettings />} />
                </Routes>
              </PrivateRoute>
            }
          />
        </Routes>
      </ErrorBoundary>
    </Box>
  );
}

export default Page;
import React, { Fragment, useEffect, useState, useRef, useContext } from 'react';
import {
  Box,
  Paper,
  Button,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  enUS, frFR, deDE
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import HistoryIcon from '@mui/icons-material/ManageSearch';
import DenyIcon from '@mui/icons-material/Cancel';
import ApproveIcon from '@mui/icons-material/CheckCircle';

import * as api from '../../services';
import { initDateRange, formatDay, calcTotalRequest, monthFromDaterange } from '../../utils/dateUtils';
import { ApprovalIcon } from '../time/ApprovalItem';
import { DateRange } from '../../components/ui/buttons';
import { Loadable } from '../../components/wrapper/Loadable';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { DisplayDialog } from '../../components/ui/DisplayDialog';
import { StaffTimes, UserTimes, TimeRequest } from './TimeObjects';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import { render } from '@testing-library/react';

import { CurrentUserContext } from '../../context/CurrentUserContext';

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}


function TimeReport() {
  const { workFunctions, translationList } = useContext(DefinitionsContext);
  const isLoading = useRef(true);
  const [dateRange, setDateRange] = useState(initDateRange());
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const renderGreyZero = (params) => {
    const style = { color: params.value === '0.00' ? 'grey' : 'inherit' };
    return <span style={style}>{params.value}</span>;
  };

  useEffect(() => {
    if (workFunctions?.length === 0) { return; }
    async function fetchData() {
      const times = await api.listTimeRequests(dateRange);
      const users = await api.listUsers();
      const approvals = (await api.getAllApprovals(dateRange[0], dateRange[1]))?.data;
      if (!times || !users || !approvals) { return; }

      const staffTimes = new StaffTimes(workFunctions);
      let timeRequest;
      times.sort((a, b) => a.userId - b.userId);
      const userIds = [...new Set(times.map(el => el.userId))];
      const userTimeMap = new Map();
      for await (let userId of userIds) {
        userTimeMap.set(userId, new UserTimes(await users.find(u => u.id === userId)));
      }

      let userTimes;
      for await (let req of times) {
        timeRequest = new TimeRequest(req);
        timeRequest.setApproval(approvals.find(a => a.timeRequestId === req.id));
        userTimes = userTimeMap.get(req.userId);
        userTimes.addRequest(timeRequest);
      }

      userTimeMap.forEach((userTime, userId) => {staffTimes.addUserTime(userTime)});

      setData(staffTimes.data);
      isLoading.current = false;
    }

    fetchData();
  }, [dateRange, workFunctions]);

  const timeWidth = 80;
  const columns = [
    //{ field: 'userFunctions', headerName: 'Functions', width: 150 },
    //{ field: 'id', headerName: 'Id', width: 60 },
    { field: 'firstName', headerName: translationList.firstname },
    { field: 'lastName', headerName: translationList.lastname },
    { field: 'pending', headerName: translationList.pending, width: timeWidth, renderCell: renderGreyZero },
    { field: 'denied', headerName: translationList.denied, width: timeWidth, renderCell: renderGreyZero },
    { field: 'total', headerName: translationList.total, width: timeWidth, renderCell: renderGreyZero },
    { field: 'direction', headerName: translationList.direction, width: timeWidth, renderCell: renderGreyZero },
    { field: 'bar', headerName: translationList.bar, width: timeWidth, renderCell: renderGreyZero },
    { field: 'door', headerName: translationList.door, width: timeWidth, renderCell: renderGreyZero },
    { field: 'wardrobe', headerName: translationList.wardrobe, width: timeWidth, renderCell: renderGreyZero },
    { field: 'booking', headerName: translationList.booking, width: timeWidth, renderCell: renderGreyZero },
    { field: 'register', headerName: translationList.register, width: timeWidth, renderCell: renderGreyZero },
    { field: 'cleaning', headerName: translationList.cleaning, width: timeWidth, renderCell: renderGreyZero },
    { field: 'light', headerName: translationList.light, width: timeWidth, renderCell: renderGreyZero },
    { field: 'sound', headerName: translationList.sound, width: timeWidth, renderCell: renderGreyZero },
    { field: 'accounting', headerName: translationList.accounting, width: timeWidth, renderCell: renderGreyZero },
    { field: 'billing', headerName: translationList.billing, width: timeWidth, renderCell: renderGreyZero },
    { field: 'advertising', headerName: translationList.advertising, width: timeWidth, renderCell: renderGreyZero },
    { field: 'posters', headerName: translationList.posters, width: timeWidth, renderCell: renderGreyZero },
    { field: 'manager', headerName: translationList.manager, width: timeWidth, renderCell: renderGreyZero },
    { field: 'executive', headerName: translationList.executive, width: timeWidth, renderCell: renderGreyZero },
  ];

  const { currentUser } = useContext(CurrentUserContext);
  const localeText = getLocaleText(currentUser.languageId);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
        <DateRange onRangeChange={(t0, t1) => {
          setDateRange([t0, t1]);
          isLoading.current = true;
        }}
        />
      </Paper>
      <Loadable isLoading={isLoading.current}>
        <DataGrid
          localeText={localeText}
          rows={data}
          columns={columns}
          disableRowSelectionOnClick
          pageSizeOptions={[20, 50, 100]}
          density='compact'
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={selection => setSelectedRows(selection)}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: {
                disableToolbarButton: false,
                fileName: `Times-${monthFromDaterange(dateRange)}.csv`,
              },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                approvalName: false,
                userFunctionNames: false,
                pending: false,
                denied: false,
              },
            },
            filter: {
              filterModel: {
                items: [{ field: 'approvalName', operator: 'contains', value: 'pending' }],
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
        />
      </Loadable>
    </Box>
  );
}

export { TimeReport }
export default TimeReport;
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PasswordChanger } from '../../features/staff/PasswordChanger';
import {
  Box,
  Paper,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { Notification } from '../ui/Notification';
import PasswordChecklist from 'react-password-checklist';
import { resetPassword } from '../../services';

function ForgotPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [new1, setNew1] = useState('');
  const [new2, setNew2] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [notify, setNotify] = useState({ text: '', severity: 'info' });
  const [isNotify, setIsNotify] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('resetToken');

  async function handleSubmit(
  ) {
    try {
      const res = await resetPassword(new1, new2, token);
      setNotify({ text: 'Password reset', severity: 'success' });
      setIsNotify(true);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      switch (error?.response?.data?.error) {
        case 'password_exist':
          setNotify({ text: 'New password must not be the same as the old password', severity: 'error' });
          break;
        case 'expired':
          setNotify({ text: 'Link expired', severity: 'error' });
          break;
        default:
          setNotify({ text: 'An error occurred', severity: 'error' });
      }
      setIsNotify(true);
    }
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        maxWidth: '300px',
        margin: 'auto',
        padding: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Notification
          text={notify.text}
          severity={notify.severity}
          isOpen={isNotify}
          onClose={() => setIsNotify(false)}
        />
        <Typography variant='h6'>Reset Password</Typography>
        <TextField
          label='New Password'
          type='password'
          value={new1}
          onChange={e => setNew1(e.target.value)}
        />
        <TextField
          label='Repeat New Password'
          type='password'
          value={new2}
          onChange={e => setNew2(e.target.value)}
        />
        {(new1 !== '' || new2 !== '') &&
          <PasswordChecklist
            rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
            minLength={8}
            value={new1}
            valueAgain={new2}
            onChange={val => setIsValid(val)}
          />
        }
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={!isValid}
            variant='contained'
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

export { ForgotPassword }
export default ForgotPassword;
import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
} from '@mui/material';
import PriorityIcon from '@mui/icons-material/PriorityHigh';

import { CurrentUserContext } from '../../context/CurrentUserContext';
import PersonalDataService from './PersonalDataService';
import BankingInformation from './BakingInformation';
import Notification from '../../components/ui/Notification';
import { TabPanel } from '../../components/ui/containers';
import * as userAPI from '../../services/userAPI';

function PersonalDataPage() {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <PersonalData userId={currentUser.id} />
  );
}

function PersonalData({ userId, disabled = false }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [activeTab, setActiveTab] = useState(0);
  const [notification, setNotification] = useState({ text: '', severity: 'info' });
  const [modalNotify, setModalNotify] = useState(false);
  const [user, setUser] = useState(userId ? null : currentUser);
  const [isComplete, setIsComplete] = useState({ 'personal': false, 'banking': false });

  useEffect(() => {
    if (userId) {
      (async () => {
        const user = await userAPI.getUser(userId);
        if (!user) return;
        setUser(user);
      })();
    }
  }, [userId]);

  useEffect(() => {
    if (notification.text) {
      setModalNotify(true);
    }
  }, [notification]);

  return (
    <Box>
      <Notification
        isOpen={modalNotify}
        onClose={() => setModalNotify(false)}
        text={notification.text}
        severity={notification.severity}
      >
      </Notification>
      <Tabs
        value={activeTab}
        onChange={(e, val) => setActiveTab(val)}
      >
        <Tab
          icon={isComplete.personal ? null : <PriorityIcon />}
          iconPosition='start'
          label='Person'
        />
        <Tab
          icon={isComplete.banking ? null : <PriorityIcon />}
          iconPosition='start'
          label='Salary'
          />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <PersonalDataService
          userId={userId}
          disabled={disabled}
          setNotification={setNotification}
          setIsComplete={() => setIsComplete({ ...isComplete, 'personal': true })}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <BankingInformation
          userId={userId}
          disabled={disabled}
          setIsComplete={() => setIsComplete({ ...isComplete, 'banking': true })}
          setNotification={setNotification}
        />
      </TabPanel>
    </Box>
  );
}

export default PersonalDataPage;
export { PersonalData, PersonalDataPage };
import React, { useState, useEffect, useContext } from 'react';
import { set, useForm } from 'react-hook-form';
import * as api from '../../services';
import {
  Box,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material';

import { FormTextInput, FormRadioInput } from '../../components/form';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import { useAreInputsValid } from '../../components/inputs/useAreInputsValid';

function checkIsComplete(data) {
  if (!data) return false;
  return (
    data.bankType && data.bankType !== '' &&
    data.bankName && data.bankName !== '' &&
    data.bankAddress && data.bankAddress !== '' &&
    data.iban && data.iban !== ''
  );
}

function checkIsMutated(data, defaultValues) {
  if (!data) return false;
  return (
    data.bankType !== defaultValues.bankType ||
    data.bankName !== defaultValues.bankName ||
    data.bankAddress !== defaultValues.bankAddress ||
    data.iban !== defaultValues.iban
  );
}

function BankingInformationService({ userId, disabled = false, setIsComplete, setNotification }) {
  const [data, setData] = useState();
  const [isSubmitable, setIsSubmitable] = useState(false);

  async function handleSubmit(data) {
    await api.addUserBankAccount(userId, data);
    setNotification({ text: 'Banking information updated', severity: 'success' });
  }

  useEffect(() => {
    (async () => {
      const res = await api.getUserBankAccount(userId);
      if (res?.data.length === 0) {
        setData([]);
      } else {
        setData(res.data.at(-1));
      }
    })();
  }, [userId]);

  useEffect(() => {
    if (checkIsComplete(data)) {
      setIsComplete();
    }
  }, [data]);

  if (!data) return <CircularProgress />;

  const defaultValues = {
    bankType: data?.bankType ?? '',
    bankName: data?.bankName ?? '',
    bankAddress: data?.bankAddress ?? '',
    iban: data?.iban ?? '',
  };

  return (
    <BankingInformationLogic
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      disabled={disabled}
    />
  );
}

function BankingInformationLogic({ defaultValues, onSubmit, image, setImage, disabled }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  async function handleSubmit(data) {
    onSubmit(data);
    //await onSubmit(data, {userFunctionNames: data.userFunctions.map( el => el.name)});
  };

  return (
    <BankingInformationView
      form={form}
      onSubmit={handleSubmit}
      disabled={disabled}
    />
  );
}

function BankingInformationView({ form, onSubmit, disabled }) {
  const [isValid, onValideChange] = useAreInputsValid();
  const { translationList } = useContext(DefinitionsContext);
  const { formState, control, handleSubmit, watch } = form;
  const { errors, isSubmitting } = formState;
  const divider = <Divider sx={{ marginTop: '10px', marginBottom: '15px' }} />

  const isComplete = checkIsComplete(watch());
  const isChanged = checkIsMutated(watch(), form.formState.defaultValues);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 1,
        gap: 1,
      }}
    >
      <FormRadioInput name='bankType'
        label={translationList.bankType}
        control={control}
        preselect={'BANK'}
        entries={[
          { value: 'BANK', label: translationList.bank },
          { value: 'POST', label: translationList.post },
        ]}
        disabled={disabled}
      />
      <FormTextInput onValideChange={onValideChange} name='bankName' label={translationList.bankName} control={control} disabled={disabled} />
      <FormTextInput onValideChange={onValideChange} name='bankAddress' label={translationList.bankAddress} control={control} disabled={disabled} />
      <FormTextInput onValideChange={onValideChange} name='iban' label={translationList.iban} control={control} disabled={disabled} />

      {!disabled &&
        <Button
          onClick={handleSubmit(onSubmit)}
          variant='contained'
          disabled={!isChanged || !isComplete || isSubmitting || disabled || !isValid}
        >
          {translationList.save}
        </Button>
      }
    </Box>
  );
}

export default BankingInformationService;
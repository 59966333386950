import React, { Fragment, useState, useContext } from 'react';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';

import dayjs from 'dayjs';

import { CurrentUserContext } from '../../context/CurrentUserContext';
import { StaticWindow } from '../../components/ui/windows';
import { CancelConfirm } from '../../components/ui/buttons';
import { TextInput } from '../../components/inputs';
import { WorkFunctionInput } from '../work-functions/WorkFunctionInput';
import ConfirmationDialog from '../../components/boilerplate/ConfirmationDialog';

import * as api from '../../services/timeAPI';
import { DefinitionsContext } from "../../context/DefinitionsContext";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
function TimeRequestCreator({ isOpen, onClose, onUpdate }) {
  const { translationList, workFunctions } = useContext(DefinitionsContext);
  const { currentUser } = useContext(CurrentUserContext);
  //TODO: Change to direction id
  const workFunDefault = 14;
  const [date, setDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs().add(-1, 'hour'));
  const [endTime, setEndTime] = useState(dayjs());
  const [workFunctionId, setWorkFunctionId] = useState(workFunDefault);
  const [description, setDescription] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [modalConfirmation, setModalConfirmation] = useState(false);

  function handleSubmit() {
    //TODO: check if data is complete
    api.requestTime(date, startTime, endTime, currentUser.id, 'direction', description, workFunctionId, onUpdate);
    handleClose();
  }

  function funIdToName(id) {
    return workFunctions.find(el => el.id === id)?.name;
  }

  function handleWorkFunctionChange(workFunctionName) {
    const workFunId = workFunctions.find(el => el.name === workFunctionName).id;
    setWorkFunctionId(workFunId);
  }

  function resetState() {
    setDate(dayjs());
    setStartTime(dayjs().add(-1, 'hour'));
    setEndTime(dayjs());
    setWorkFunctionId(14);
    setDescription('');
    setIsValid(true);
  }

  function handleClose() {
    resetState();
    onClose();
  }

  function handleValidChange(label, isFieldValid) {
    setIsValid(isFieldValid);
  }

  return (
    <Fragment>
      <ConfirmationDialog
        isOpen={modalConfirmation}
        text='Are you sure you want to submit this time request?'
        onClose={() => setModalConfirmation(false)}
        onConfirm={handleSubmit}
      />
      <StaticWindow
        isOpen={isOpen}
        onClose={handleClose}
        title={translationList.logTime}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              timezone="Europe/Zurich"
              format={'DD.MM.YYYY'}
              value={date}
              onChange={(newDate) => setDate(newDate)}
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: 1,
              }}
            >
              <TimePicker
                fullWidth
                timezone="Europe/Zurich"
                ampm={false}
                label={translationList.begin}
                value={startTime}
                onChange={(newTime) => setStartTime(newTime)}
                sx={{ 'width': '100%' }}
              />
              <TimePicker
                fullWidth
                timezone="Europe/Zurich"
                ampm={false}
                label={translationList.end}
                value={endTime}
                onChange={(newTime) => setEndTime(newTime)}
                sx={{ 'width': '100%' }}
              />
            </Box>
            <WorkFunctionInput value={funIdToName(workFunctionId)} onChange={handleWorkFunctionChange} />
            <TextInput
              fullWidth
              multiline
              rows={4}
              label={translationList.description}
              value={description}
              onChange={e => setDescription(e.target.value)}
              onValidChange={handleValidChange}
              max={1000}
              variant='outlined'
            />
          </LocalizationProvider>
        </Box>
        <CancelConfirm
          confirmLabel={translationList.submit}
          cancelLabel={translationList.cancel}
          disabled={!isValid}
          onCancel={handleClose}
          onConfirm={() => setModalConfirmation(true)}
        />
      </StaticWindow>
    </Fragment>
  );
}

export { TimeRequestCreator }
export default TimeRequestCreator;
import React, { Fragment, useState, useEffect, useContext } from 'react';

import { PostItem } from './PostItem';
import { PostCreator } from './PostCreator';
import { StaticWindow } from '../../components/ui/windows/StaticWindow';
import { AddableListContainer } from '../../components/ui/containers/ListContainer';

import { getPosts } from '../../services';
import PostInspector from './PostInspector.jsx';
import { Post } from './PostObjects.js';
import { hasPermission, Permission } from '../../components/wrapper/Permission';
import { CurrentUserContext } from '../../context/CurrentUserContext.js';
import { set } from 'react-hook-form';

function PostBrowser({ topicId }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalEditor, setModalEditor] = useState(false);
  const [modalInspector, setModalInspector] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);

  function handleSelect(post) {
    setSelectedPost(post);
    setModalInspector(true);
  }

  function handleInspectorClose() {
    setModalInspector(false);
    setSelectedPost(null);
  }

  function handleSubmit() {
    setModalEditor(false);
    updatePosts();
  }

  function updatePosts() {
    setUpdateCounter(updateCounter + 1);
  }

  useEffect(() => {
    (async () => {
      //await new Promise(resolve => setTimeout(resolve, 500));
      const res = await getPosts(topicId);
      if (!res?.data) {return; }
      setPosts(res.data.map(el => new Post(el)));
    })();
  }, [updateCounter, topicId]);

  return (
    <Fragment>
      <StaticWindow
        isOpen={modalEditor}
        onClose={() => setModalEditor(false)}
      >
        <PostCreator
          topicId={'feed'}
          onClose={handleSubmit}
        />
      </StaticWindow>
      <StaticWindow
        isOpen={modalInspector}
        onClose={handleInspectorClose}
      >
        <PostInspector
          post={selectedPost}
          onClose={handleInspectorClose}
          onChange={updatePosts}  
          topicId={topicId}
        />
      </StaticWindow>
      <AddableListContainer
        onAdd={hasPermission(5, currentUser) ? () => setModalEditor(true) : null}
        sx={{
          maxHeight: '90vh'
        }}
      >
        {posts.map(el => <PostItem post={el} key={el.id} onClick={handleSelect} />)}
      </AddableListContainer>
    </Fragment>
  );
}

export { PostBrowser }
export default PostBrowser;
import React, {useState, useEffect, useContext} from 'react';
import * as api from '../../services';
import {
  CircularProgress,
} from '@mui/material';

import PersonalDataLogic from './PersonalDataLogic';
import { set } from 'react-hook-form';
import {DefinitionsContext} from "../../context/DefinitionsContext";

function checkIsComplete(data) {
  if (!data) return false;
  return (
  data.firstName          && data.firstName !== '' &&
  data.lastName           && data.lastName  !== '' &&
  data.street             && data.street    !== '' &&
  data.zip                && data.zip       !== '' &&
  data.location           && data.location  !== '' &&
  data.birthdate          && data.birthdate !== '1900-01-01' &&
  data.email              && data.email     !== '' &&
  data.entryDate          && data.entryDate !== '' &&
  data.phoneNumber        && data.phoneNumber !== '' &&
  data.mobileNumber       && data.mobileNumber !== '' &&
  data.correspondanceType && data.correspondanceType !== '' &&
  data.ahvNumber          && data.ahvNumber !== '' &&
  data.numberOfKids       !== null &&
  data.nationality        && data.nationality !== '' &&
  data.workingPermit      && data.workingPermit !== '' &&
  data.taxCanton          && data.taxCanton !== ''
);
}

function PersonalDataService({ userId, disabled, setIsComplete, setNotification}) {
  const [data, setData]   = useState();
  const [image, setImage] = useState(null);
  const { translationList } = useContext(DefinitionsContext);

  async function handleSubmit(data, userFunctions) {
    try {
      await api.updateUser(userId, data);
      setNotification({ text: translationList.userDataUpdToDate, severity: 'success' });
    } catch(e) {
      switch (e?.response?.data?.error) {
        case 'wrong_format':
          setNotification({ text: `${translationList.wrongFormat}: ${e.response.data.message}`, severity: 'error' });
          break;
        default:
          setNotification({ text: `${translationList.errorOccured}: ${e?.response?.data?.message}`, severity: 'error' });
          break;
      }
    }

    if (image) {
      api.addProfilePicture(userId, image);
    }
  }

  useEffect(() => {
    if (checkIsComplete(data)) {
      setIsComplete();
    }
  }, [data]);

  useEffect(() => {
    if (userId) {
      (async () => {
        const res = await api.getUser(userId)
        if (res?.status === 200 && res?.data) {
          setData(res.data);
        }
        const res2 = await api.getProfilePicture(userId);
        if (res2?.data?.image) {
          setImage(res2.data.image);
        }
      })();
    }
  }, [userId]);

  if (!data) return <CircularProgress />;

  const defaultValues = {
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    street: data.street ?? '',
    zip: data.zip ?? '',
    location: data.location ?? '',
    birthdate: data.birthdate ?? '1900-01-01',
    email: data.email ?? '',
    entryDate: data.entryDate ?? '',
    phoneNumber: data.phoneNumber ?? '',
    mobileNumber: data.mobileNumber ?? '',
    correspondanceType: data.correspondanceType ?? 'email',
    ahvNumber: data.ahvNumber ?? '',
    numberOfKids: data.numberOfKids ?? 0,
    userFunctions: data.userFunctions ?? [],
    nationality: data.nationality ?? '',
    workingPermit: data.workingPermit ?? '',
    taxCanton: data.taxCanton ?? '',
    otherEmployer: data.otherEmployer ?? '',
  };

  if (!defaultValues) return <CircularProgress />;

  return (
    <PersonalDataLogic
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      image={image}
      setImage={setImage}
      userId={userId}
      disabled={disabled}
      sx={{
        width: '100%',
        height: '100%',
      }}
    />
  );
}

export default PersonalDataService;
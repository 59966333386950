import api from './api';

// Function to set up interceptors
function setupInterceptors(navigate, logout) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.code === 'ERR_NETWORK') {
        navigate('/error', { replace: true, state: { message: 'The server can not be reached.' } });
        return Promise.resolve
      }
      switch (error.response?.status) {
        case 400:
          switch (error.response.data?.error) {
            case 'not_readable':
              console.error('Not readable', error.response?.data?.message);
              logout();
              navigate('/error', { replace: true, state: { message: error.response.data?.message } });
              return Promise.resolve();
            case 'wrong_username':
              throw error;
              return Promise.resolve(error);
            default:
              console.error('Unknown 400 error', error.response.data?.error);
              return Promise.reject(error);
          }
        case 401:
          switch (error.response.data?.error) {
            case 'bad_credentials':
              return Promise.reject(error);
            case 'expired':
            case 'invalid':
            case 'bad_signature':
            case 'missing_token':
            case 'revoked':
              console.warn('Caught:', error.response?.data?.error, error.response?.data?.message);
              //logout();
              return Promise.resolve();
            default:
              console.error('Unknown 401 error', error.response.data?.error, error.response.data?.message);
              return Promise.reject(error);
          }
        case 403:
          switch (error.response.data?.error) {
            case 'archived':
              console.error('Caught', error.respone?.data?.error, error.response?.data?.message);
              return Promise.reject(error);
            case 'missing_token':
            case 'access_denied':
              console.warn('Caught:', error.response?.data?.error, error.response?.data?.message);
              logout();
              navigate('/error', { replace: true, state: { message: error.response?.data?.message} });
              return Promise.resolve();
            default:
              console.error('Unknown 403 error', error.response?.data?.error);
              return Promise.reject(error);
          }
        case 409:
          console.warn('Caught 409:', error, error.response?.data?.error, error.response?.data?.message);
          return Promise.reject(error);
        default:
          console.error('Unhandled error status', error.response?.status, error);
          return Promise.reject(error);
      }
    }
  );
};

export default setupInterceptors;
export { setupInterceptors };
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TicketIcon from '@mui/icons-material/ConfirmationNumber';

import { EditableListContainer } from '../../components/ui/containers';
import { loadTicket, Ticket } from './ticket';
import { EventContext } from '../../context/EventContext';
import * as api from '../../services';
import { DefinitionsContext } from "../../context/DefinitionsContext";
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { NumberInput } from '../../components/inputs';
import { hasPermission } from '../../components/wrapper/Permission';

const ticketTypes = { 1: 'presale', 2: 'free', 3: 'onsite' };

function TicketSales({ tickets, setTickets, onValidChange, disabled = false }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { event } = useContext(EventContext);
  const [total, setTotal] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (disabled) {
      setIsDisabled(true);
      return;
    }
    if (!currentUser) {
      setIsDisabled(true);
    }
    else {
      setIsDisabled(!hasPermission(5, currentUser));
    }
  }, [currentUser, disabled]);

  function updateTicket(ticket) {
    const tmp = [...tickets.filter(el => el.id !== ticket.id), ticket];
    tmp.sort((a, b) => a.price - b.price);
    tmp.sort((a, b) => a.typeId - b.typeId);
    setTickets(tmp);
  }

  useEffect(() => {
    setTotal(tickets.reduce((tot, el) => tot + el.total, 0));
  }, [tickets]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '24px',
        }}
      >
        <Typography variant='h6'>{total} CHF</Typography>
      </Box>
      <EditableListContainer
        onAdd={() => setTickets([...tickets, new Ticket(event.id, 3)])}
        isDisabled={isDisabled}
        sx={{
          maxHeight: '40vh',
        }}
      >
        {tickets.map(el => {
          return <TicketItem
            ticket={el}
            disabled={isDisabled}
            updateTicket={updateTicket}
            onDelete={(obj) => setTickets(tickets.filter(el => el.id !== obj.id))}
            id={el.id}
            onValidChange={onValidChange}
            key={el.id}
          />
        })}
      </EditableListContainer>
    </Box>
  )
}

function TicketItem({ ticket, updateTicket, onDelete, onValidChange, id, disabled }) {
  const { translationList } = useContext(DefinitionsContext);
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: 1,
        marginRight: '6px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center'
          }}
        >
          <TicketIcon />
          <Typography variant='h6'>{translationList[ticketTypes[ticket.typeId]]}{(ticket.typeId === 3) ? ` - ${ticket.price} CHF` : ''}</Typography>
        </Box>
        <Typography variant='h6' sx={{ marginRight: '10px' }}>
          {ticket.quantity} | {ticket.total} CHF
        </Typography>
      </Box>

      <Divider sx={{ bgcolor: 'secondary.dark', marginBottom: 1 }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          width: '100%',
        }}
      >
        {ticket.typeId === 2 ? (
          <Box sx={{ width: '100%', }} />
        ) : (
          <NumberInput
            isRequired
            label={translationList.price}
            value={ticket.price}
            onChange={(e) => { ticket.price = e; updateTicket(ticket); }}
            disabled={disabled}
            onValidChange={onValidChange}
            id={id}
          />
        )}
        {ticket.typeId === 3 ? (
          <>
            <NumberInput
              isRequired
              label={translationList.startNr}
              min={1}
              value={ticket.start}
              onChange={(e) => { ticket.start = e; updateTicket(ticket); }}
              disabled={disabled}
              onValidChange={onValidChange}
              id={id}
            />
            <NumberInput
              isRequired
              label={translationList.closingNr}
              min={ticket.start}
              value={ticket.end}
              onChange={(e) => { ticket.end = e; updateTicket(ticket); }}
              disabled={disabled}
              onValidChange={onValidChange}
              id={id}
            />
          </>
        ) : (
          <NumberInput
            isRequired
            label={translationList.quantity}
            value={ticket.quantity}
            onChange={(e) => { ticket.quantity = e; updateTicket(ticket); }}
            disabled={disabled}
            onValidChange={onValidChange}
            id={id}
          />
        )
        }
        {!disabled &&
          <IconButton
            disabled={ticket.typeId !== 3}
            onClick={() => onDelete(ticket)}
            size='small'
            sx={{ gridColumn: '12' }}
            color='secondary'
          >
            <ClearIcon />
          </IconButton>
        }
      </Box>
    </Paper>
  )
}

export { TicketSales }
export default TicketSales;
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  Divider,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import FeedIcon from '@mui/icons-material/Feed';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import FolderIcon from '@mui/icons-material/Folder';
import EventIcon from '@mui/icons-material/Event';
import ApprovalIcon from '@mui/icons-material/Approval';
import ContactsIcon from '@mui/icons-material/Contacts';
import EventEvaluationIcon from '@mui/icons-material/EventAvailable';
import TimeReportIcon from '@mui/icons-material/Assessment';

import { CurrentUserContext } from '../../context/CurrentUserContext';
import MenuEntry from './MenuEntry';
import Permission from '../../components/wrapper/Permission';
import { DefinitionsContext } from "../../context/DefinitionsContext";

function MenuList({ onClick }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [selectedKey, setSelectedKey] = useState('');
  const navigate = useNavigate();
  const { translationList } = useContext(DefinitionsContext);

  function handleClick(route, title, event) {
    setSelectedKey(route);
    navigate(route);
    onClick(title);
  }

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List component="nav" aria-label="info">

        <Permission level={3}>
          <MenuEntry
            title={translationList.feed}
            route='/feed'
            icon={<SummarizeIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

      </List>

      <Divider />

      <List component="nav" aria-label="resource management">

        <Permission level={3}>
          <MenuEntry
            title={translationList.eventsPlanning}
            route='/events'
            icon={<EventIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

        <Permission level={3}>
          <MenuEntry
            title={translationList.eventEvaluation}
            route='/eventeval'
            icon={<EventEvaluationIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

        <Permission level={3}>
          <MenuEntry
            title={translationList.timeManagement}
            route='/timemanagement'
            icon={<AccessTimeIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

        {/*
        <Permission level={3}>
          <MenuEntry
            title={translationList.expenses}
            route='/expenses'
            icon={<AttachMoneyIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>
        */}

        <Permission level={3}>
          <MenuEntry
            title={translationList.personalData}
            route='/personaldata'
            icon={<PersonIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

        <Divider />

        <Permission level={5}>
          <MenuEntry
            title={translationList.timeReview}
            route='/timereview'
            icon={<ApprovalIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

        <Permission level={5}>
          <MenuEntry
            title={translationList.timeReport}
            route='/timereport'
            icon={<TimeReportIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

        <Permission level={5}>
          <MenuEntry
            title={translationList.userManagement}
            route='/usermanagement'
            icon={<PeopleIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>

        <Permission level={3}>
          <MenuEntry
            title={translationList.contact}
            route='/contacts'
            icon={<ContactsIcon />}
            selectedKey={selectedKey}
            handleClick={handleClick}
          />
        </Permission>
      </List>
    </Box>
  );
}

export default MenuList;
import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { v4 as uuidv4 } from 'uuid';

import * as userAPI from '../../services/userAPI';
import { findUserById, removeUserById, userToAssignee } from '../../utils/userLists';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { WorkFunctionChip } from '../work-functions/WorkFunctionItem';
import { EventContext } from '../../context/EventContext';
import { DefinitionsContext } from '../../context/DefinitionsContext';
import { createAssigneePlaceholder } from './AssigneeObject';
import { ContactlessOutlined } from '@mui/icons-material';


function AssigneePlaceholderSelect({ onSelect }) {
  const { assignees, setAssignees } = useContext(EventContext);
  const {workFunctions} = useContext(DefinitionsContext);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const funsToShow = [
    'door',
    'register',
    'wardrobe',
    'sound',
    'light',
    'bar',
  ]

  //useEffect( ()=> {
  //  userAPI.listUsers( (res) => setUsers(res) );
  //}, []);


  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <List>
        {workFunctions.filter(el=>funsToShow.includes(el.name)).map(
          el => { return (
              <PlacholderInputItem
                funName={el.name}
                key={el.id}
                setAssignees={setAssignees}
              />
            )
          })}
      </List>
    </Box>
  );
}

//TODO: Id with ammount state could be dangerous
/* Pop up to add placholder assignees per function. */
function PlacholderInputItem({ funName}) {
  const { eventState, assignees, setAssignees } = useContext(EventContext);
  const [ funAssignees, setFunAssignees ] = useState([]);
  const ref = useRef(assignees.filter( el => el.userFunctionName === funName).length);

  useEffect( () => {
    setFunAssignees(assignees.filter( el => el.userFunctionName === funName));
  }, [assignees]);

  return (
    <ListItem
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', }} >
        <Box sx={{width: '128px'}}>
          <WorkFunctionChip workFunction={funName}/>
        </Box>
        <Box>
          <IconButton
            disabled={funAssignees.filter( el => el.status === 'new').length <= 0}
            onClick={ () => {
              if (ref.current > 0) {
                const tmpAssignees = funAssignees.filter( el => el.status === 'new');
                if (!tmpAssignees.length > 0) { return; }
                setAssignees( assignees.filter( el => el.id !== tmpAssignees.at(-1).id ));
                ref.current -= 1;
              }
              }
            }
          ><RemoveIcon /></IconButton>
          {ref.current}
          <IconButton
            onClick={ () => {
              setAssignees([...assignees,
                createAssigneePlaceholder(
                  funName,
                  eventState.startTime,
                  eventState.endTime
                )
              ]);
              ref.current += 1;
            }}
          ><AddIcon /></IconButton>
        </Box>
      </Box>
    </ListItem>
  )
}

export { AssigneePlaceholderSelect };
export default AssigneePlaceholderSelect;
import { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import { addPost } from '../../services';
import { CurrentUserContext } from '../../context/CurrentUserContext';
import { TextInput, useAreInputsValid } from '../../components/inputs';
import {DefinitionsContext} from "../../context/DefinitionsContext";


function PostCreator({ topicId, onClose }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [title, setTitle]     = useState('');
  const [content, setContent] = useState('');
  const [funs, setFuns]       = useState(currentUser.userFunctions);
  const [isValid, onValidChange] = useAreInputsValid();
  const { translationList } = useContext(DefinitionsContext);

  async function handleSubmit() {
    if (!title && !content) {return; }
    await addPost(topicId, title, content, funs.map( el => el.name ));
    onClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <TextInput
        label={translationList.title}
        value={title}
        onChange={ e => setTitle(e.target.value) }
        onValidChange={onValidChange}
      />
      <TextInput
        label={translationList.content}
        multiline
        rows={12}
        max={3000}
        value={content}
        onChange={ e => setContent(e.target.value) }
        onValidChange={onValidChange}
      />
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          disabled={!isValid}
          variant='contained'
          onClick={handleSubmit}
        >
          {translationList.post}
        </Button>
      </Box>
    </Box>
  )
}

export default PostCreator;
export { PostCreator };
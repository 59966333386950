import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  List,
  Divider,
  CircularProgress,
  Typography,
} from '@mui/material';
import { DefinitionsContext } from '../../context/DefinitionsContext';

import AssigneeItem from './AssigneeItem';
import { WorkFunctionChip } from '../work-functions/WorkFunctionItem';

/* Scrollable Box showing assigned users per user function (doors, bar, etc) */
function AssigneeList({ assignees, isDisabled }) {
  const { workFunctions } = useContext(DefinitionsContext);
  const [funStaff, setFunStaff] = useState([]);

  function getWorkFunctionId(workFunctionName) {
    const fun = workFunctions.filter(el => el.name === workFunctionName);
    if (fun && fun.length > 0) { return fun[0].id }
    else { return 99 }
  }

  useEffect(() => {
    if (assignees) {
      const staffObj = {};
      for (let el of assignees) {
        if (!staffObj.hasOwnProperty(el.userFunctionName)) {
          staffObj[el.userFunctionName] = [];
        }
        staffObj[el.userFunctionName].push(el);
      };
      setFunStaff(Object.entries(staffObj).sort((a, b) => getWorkFunctionId(a[0]) - getWorkFunctionId(b[0])));
    }
  }, [assignees]);

  if (!assignees) return <CircularProgress />

  return (
    <List>
      {funStaff.map(([funName, assignees]) => <AssigneeListFunctions isDisabled={isDisabled} functionName={funName} assignees={assignees} key={funName} />)}
    </List>
  );
}

/* Show all assignees of one specific function. Also draws a little title for each function.*/
function AssigneeListFunctions({ functionName, assignees, onDelete, isDisabled }) {
  const { translationList } = useContext(DefinitionsContext);

  function sortAssignees(assignees) {
    return assignees.sort((a, b) => {
      const [aHours, aMinutes] = a.startTime.split(':').map(Number);
      const [bHours, bMinutes] = b.startTime.split(':').map(Number);

      // Convert the times to a comparable format, shifting morning times by 24 hours
      const aTotalMinutes = (aHours < 13 ? aHours + 24 : aHours) * 60 + aMinutes;
      const bTotalMinutes = (bHours < 13 ? bHours + 24 : bHours) * 60 + bMinutes;

      // Compare the total minutes
      return aTotalMinutes - bTotalMinutes;
    });
  }


  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          marginBottom: '8px',
          marginTop: '24px',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '20px',
          }}
        >
          <Divider
            sx={{
              bgcolor: 'secondary.dark',
            }}
          />
        </Box>
        <WorkFunctionChip workFunction={functionName} />
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Divider
            sx={{
              bgcolor: 'secondary.dark',
            }}
          />
        </Box>
      </Box>
      {sortAssignees(assignees).map(el => <AssigneeItem assignee={el} isDisabled={isDisabled} key={el.eventUserId} />)}
    </Box>
  );
}

export { AssigneeList }
export default AssigneeList;
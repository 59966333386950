import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  ButtonBase,
  IconButton,
  Typography,
} from '@mui/material';
import { getUser } from '../../services';
import { UserAvatar } from '../users/UserAvatar';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as api from '../../services';

import { CurrentUserContext } from '../../context/CurrentUserContext';


function PostItem({ post, onClick = null, onChange = null, onClose = null, onEdit = null }) {
  const [author, setAuthor] = useState();
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    if (post) {
      (async () => {
        const res = await getUser(post.author);
        if (res.status === 200 && res.data) {
          setAuthor(res.data);
        }
      })();
    }
  }, [post]);

  if (!post) { return; }

  return (
    <Box>
      <ButtonBase
        disabled={onClick === null}
        onClick={() => onClick(post)}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: 1,
          padding: 1,
        }}
      >
        <UserAvatar user={author} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'space-between',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='h5'>{post.title}</Typography>
          </Box>
          <Typography variant='body2' color='grey'>{post.age}</Typography>
          <Typography
            variant='body1'
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {post.content}
          </Typography>
        </Box>
      </ButtonBase>
      <Divider />
      {(currentUser?.id === author?.id && !onClick) &&
        <PostActions post={post} onEdit={onEdit} onChange={onChange} onClose={onClose}/>
      }
    </Box>
  );
}

function PostActions({ post, onEdit, onChange, onClose }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        zIndex: 100,
      }}
    >
      <IconButton
        onClick={onEdit}
      >
        <EditIcon
        />
      </IconButton>
      <IconButton>
        <DeleteIcon
          onClick={
            e => {
              api.deletePost(post.id)
              onChange()
              onClose()
            }}
        />
      </IconButton>
    </Box>
  )
}

export { PostItem }
export default PostItem;
import React from 'react';
import { useContext, createContext, useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Item } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { CurrentUserContext } from './context/CurrentUserContext';
import { DefinitionsContext } from './context/DefinitionsContext';

import Page from './features/navigation/Page';

import { darkTheme } from './styles/themes';

import * as api from './services';
import { mapTranslations } from './utils/translationMapper';

function App() {
  const [currentUser, setCurrentUser] = useState(() => {
    // Retrieve the user from localStorage on initial render
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser === 'undefined') {
      localStorage.removeItem('currentUser');
      return null
    };
    return (storedUser) ? JSON.parse(storedUser) : null;
  });
  const [definitions, setDefinitions] = useState({
    'workFunctions': [],
    'eventCategories': [],
    'responsibilities': [],
    'translationList': [],
  });

  async function refreshToken() {
    if (!currentUser) { return; }
    try {
      const response = await api.refreshToken();
    } catch (error) {
      console.error('Error refreshing token:', error);
      // Handle token refresh failure, e.g., redirect to login
    }
  };

  useEffect(() => {
    const delay =  60 * 60 * 1000 - 50000;

    const timeoutId = setTimeout(() => {
      refreshToken();
      const intervalId = setInterval(() => {
        refreshToken();
      }, delay);

      // Cleanup interval on unmount or currentUser change
      return () => {
        clearInterval(intervalId)
      };
    }, delay);

    // Cleanup timeout on unmount or currentUser change
    return () => {
      clearTimeout(timeoutId)
    };
  }, [currentUser]);

  useEffect(() => {
    const storedUser = localStorage.getItem('currentUser');
    if (currentUser) {
      if (!currentUser.account) {
        (async () => {
          const account = (await api.getAccount(currentUser.id)).data;
          setCurrentUser({ ...currentUser, account });
        })()
      } 
      if (!storedUser) {
        console.log('Current user not stored but in context, setting storage now', currentUser);
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
      } else {
        //console.log('Current user stored, updating now', currentUser);
        //localStorage.setItem('currentUser', JSON.stringify(currentUser));
      }
    } else {
      if (storedUser) {
        console.log('Current user stored but not in context, setting context now', storedUser);
        setCurrentUser(JSON.parse(storedUser));
      }
      else {
        console.log('No current user in context or storage');
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
      }
    }
    return () => {
      if (currentUser) {
        console.log('Clearing local storage');
        localStorage.clear('currentUser');
      }
    }
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      if (!currentUser?.account) { return; }
      const translationList = (await mapTranslations(currentUser.languageId));
      const workFunctions = (await api.listUserFunctions())?.data;
      const eventCategories = (await api.getAllEventCategories())?.data;
      const responsibilities = (await api.getAllEventResponsibilityTypes())?.data;
      setDefinitions({
        ...definitions,
        workFunctions,
        eventCategories,
        responsibilities,
        translationList,
      });
    })();
  }, [currentUser])

  useEffect(() => {
  }, [])

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      <DefinitionsContext.Provider value={definitions}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Router>
            <Box
              name='vertical-master-container'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
              }}
            >
              <Page />
            </Box>
          </Router>
        </ThemeProvider>
      </DefinitionsContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export default App;
import React, { useState, Fragment } from 'react';
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import CssBaseline from '@mui/material/CssBaseline';

import MenuList from './MenuList';
import Settings from './Settings';
import HideOnScroll from '../../components/wrapper/HideOnScroll';

function MobileMenu(props) {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [title, setTitle] = useState('ISC Time');

  function handleClick(title) {
    setTitle(title);
    setIsMenuActive(false);
  }

  return (
    <Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
      <AppBar position="static" enableColorOnDark >
        <Toolbar>
          <IconButton
            onClick={ () => setIsMenuActive(true) }
            size="medium"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>

          <IconButton
            onClick={ () => setIsSettings(true) }
            size="large"
            edge="start"
            color="inherit"
          >
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      <Drawer
        anchor='top'
        open={isMenuActive}
        onClose={ () => setIsMenuActive(false) }
      >
        <MenuList
          onClick={handleClick}
        />
      </Drawer>
      <Drawer
        anchor='top'
        open={isSettings}
        onClose={ () => setIsSettings(false) }
      >
        <Settings onClose={() => setIsSettings(false)}/>
      </Drawer>
    </Fragment>
  );
}

export default MobileMenu;
import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
  GridFilterModel,
  GridToolbar,
  enUS, frFR, deDE
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';

import * as userAPI from '../../services/userAPI';
import { findUserById, removeUserById } from '../../utils/userLists';
import { userToAssignee } from './AssigneeObject';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { EventContext } from '../../context/EventContext';
import {DefinitionsContext} from "../../context/DefinitionsContext";
import { filterArchived } from '../../utils/userLists';

import { CurrentUserContext } from '../../context/CurrentUserContext';

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

function AssigneeSelect({ onSelect, initialFilter=''}) {
  const { translationList } = useContext(DefinitionsContext);
  const { assignees, event } = useContext(EventContext);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);

  //TODO: Fix case where user has no userFunction assigned. Will fail at userToAssignee(..., userFunctions[0])
  const columns = [
    { field: 'firstName', headerName: translationList.firstname, width: 80 },
    { field: 'lastName', headerName: translationList.lastname, width: 80 },
    { field: 'funString', headerName: translationList.workFunctions, width: 820 },
    { field: 'userFunctions',
      headerName: translationList.functions,
      width: 80,
      renderCell: (userFuns) => (
        <WorkFunctionAvatar max={2} workFunctions={userFuns.value}/>
      )
    },
    {
      field: 'add',
      headerName: translationList.add,
      sortable: false,
      width: 30,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<AddIcon />}
          color='secondary'
          onClick={ () => {
            const user = findUserById(data, params.row.id);
            onSelect(user);
          } }
          label={translationList.add}
        />
      ),
    },
  ];

  function filterAlreadyAssigned(all, assigned) {
      const assigneeIds = assigned.map( el => el.userId)
      return all.filter( el => !assigneeIds.includes(el.id) );
  }

  useEffect( ()=> {
    userAPI.listUsers( (res) => setUsers(filterArchived(res)));
  }, []);

  useEffect( () => {
    const tmp = [];
    for (let el of users) {
      // Make string of userfunctions to make it available to filter
      const funString = el.userFunctions.map( fun => fun.name ).join(',');  
      tmp.push({...el, funString});
    }
    setData( filterAlreadyAssigned(tmp, assignees) );
  }, [users]);

  useEffect( () => {
    if (data) {
      setData( filterAlreadyAssigned(data, assignees) );
    }
  }, [assignees])

  const handleEditClick = (userId) => {
    // Handle the edit action here. You can navigate to an edit page or show a modal, for example.
  };

  const { currentUser } = useContext(CurrentUserContext);
  const localeText = getLocaleText(currentUser.languageId);

  return (
    <Box sx={{ height: '100%', width: '100%', minWidth: '320px'}}>
      <DataGrid
        autoHeight={true}
        rows={data}
        columns={columns}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
        disableColumnSelector
        disableDensitySelector 
        disableColumnFilter
        localeText={localeText}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true},
            csvOptions: { disableToolbarButton: true},
          },
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [initialFilter]
            }
          },
          columns: {
            columnVisibilityModel: {
              funString: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        sx={{
          height: '100%',
          width: '100%',
        }}
      />
    </Box>
  );
}

export default AssigneeSelect;
export { AssigneeSelect };
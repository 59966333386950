import React, {Fragment, useEffect, useState, useRef, useContext} from 'react';
import {
  Box,
  Paper,
  Button,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  enUS, frFR, deDE
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import HistoryIcon from '@mui/icons-material/ManageSearch';
import DenyIcon from '@mui/icons-material/Cancel';
import ApproveIcon from '@mui/icons-material/CheckCircle';

import * as api from '../../services';
import { initDateRange, formatDay, calcTotalRequest, monthFromDaterange } from '../../utils/dateUtils';
import { ApprovalIcon } from '../time/ApprovalItem';
import { DateRange } from '../../components/ui/buttons';
import { Loadable } from '../../components/wrapper/Loadable';
import { WorkFunctionAvatar } from '../work-functions/WorkFunctionItem';
import { SecondaryWindow } from '../../components/ui/windows';
import { TimeRequestDetails } from './TimeRequestDetails';
import {DefinitionsContext} from "../../context/DefinitionsContext";
import {CurrentUserContext} from "../../context/CurrentUserContext";

function getLocaleText(languageId) {
  switch (languageId) {
    case 1:
      return deDE.components.MuiDataGrid.defaultProps.localeText;
    case 2:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
    case 3:
      return frFR.components.MuiDataGrid.defaultProps.localeText;
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText;
  }
}

const statusMap = {1: 'pending', 2: 'approved', 3: 'denied'}

function TimeReview() {
  const { translationList, workFunctions } = useContext(DefinitionsContext);
  const isLoading = useRef(true);
  const [dateRange, setDateRange] = useState(initDateRange());
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [detail, setDetail] = useState('');
  const [actionCount, setActionCount] = useState(0);
  const [isLocked, setIsLocked] = useState(false);

  async function handleApproval(timeRequstId,  status) {
    setIsLocked(true);
    try {
    await api.updateApproval(timeRequstId, status);
    setActionCount(actionCount + 1);
    } catch (e) {
      console.error(e);
    }
    finally {
      setIsLocked(false);
    }
  }

  function handleMultipleApproval(timeRequestId, status) {
    let tmp;
    for (let reqId of timeRequestId) {
      tmp = data.find( el => el.id === reqId);
      if (tmp.approval.status.name !== status) {
        handleApproval(reqId, status);
      }
      else {
      }
    }
    setSelectedRows([]);
    setShouldUpdate(true);
  }

  useEffect( () => {
    (async () => {
      const times = await api.listTimeRequests(dateRange);
      const users = await api.listUsers();
      const approvals = (await api.getAllApprovals(dateRange[0], dateRange[1]))?.data;
      const events = await api.getEventsBetweenDates(dateRange[0], dateRange[1]);

      if (times === undefined || approvals === undefined) {return;}
      const newData = [];
      for await (let req of times) {
        req.total = calcTotalRequest(req);
        //req.approval =  await api.getApproval(req.id);
        req.approval = approvals.find( a => a.timeRequestId === req.id );
        req.user = await users.find( u => u.id === req.userId);
        req.event = (req.eventId) ? await events.find( e => e.event.id === req.eventId)?.event : null;

        newData.push(req);
      }
      setData(newData);
      isLoading.current = false;
    })()
  }, [dateRange, actionCount]);

  useEffect( () => {
    setShouldUpdate(true);
  }, [dateRange]);
  const { currentUser } = useContext(CurrentUserContext);
  const localeText = getLocaleText(currentUser.languageId);
  const columns = [
    //{ field: 'userFunctions', headerName: 'Functions', width: 150 },
    //{ field: 'id', headerName: 'Id', width: 60 },
    { field: 'approval', headerName: '', width: 60, renderCell: params => <ApprovalIcon approval={params.row.approval} />},
    { field: 'approvalName', headerName: translationList.status, valueGetter: (params) => params.row.approval.status.name },
    { field: 'total', headerName: translationList.total, width: 70 },
    { field: 'startTime', headerName: translationList.begin, width: 70 },
    { field: 'endTime', headerName: translationList.end, width: 70 },
    { field: 'date', headerName: translationList.date, width: 90, valueGetter: (params) => formatDay(params.value)  },
    { field: 'firstName', headerName: translationList.firstname, valueGetter: params => params.row.user.firstName},
    { field: 'lastName', headerName: translationList.lastname, valueGetter: params => params.row.user.lastName},
    { field: 'function', headerName: translationList.function, width: 110, renderCell: params => <WorkFunctionAvatar workFunctions={[params.row.userFunction]} max={3} size='22px'/>},
    { field: 'functionName', headerName: translationList.functionName, width: 110, valueGetter: params => params.row.userFunction?.name },
    { field: 'event', headerName: translationList.event, width: 110, valueGetter: params => params.row.event && params.row.event.name },
    { field: 'requestType', headerName: 'Type', width: 70, valueGetter: params => params.row.requestType?.name},
    {
      field: 'actions',
      headerName: translationList.actions,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Box >
        <GridActionsCellItem
          icon={<ApproveIcon />}
          disabled={params.row.approval.status.name !== 'pending' || isLocked}
          onClick={() => handleApproval(params.row.id, 'approved')}
          label={translationList.approve.toLowerCase()}
        />
        <GridActionsCellItem
          icon={<DenyIcon />}
          disabled={params.row.approval.status.name !== 'pending' || isLocked}
          onClick={() => handleApproval(params.row.id, 'denied')}
          label={translationList.deny.toLowerCase()}
        />
        <GridActionsCellItem
          icon={<HistoryIcon />}
          onClick={() => {setDetail(params.row); setModalDetail(true);}}
          label={translationList.history.toLowerCase()}
        />
      </Box>
    ),
  },
];


return (
  <Fragment>
    <SecondaryWindow
      title='Time Request Details'
      isOpen={modalDetail}
      onClose={ () => setModalDetail(false) }
      text={detail}
    >
      <TimeRequestDetails data={detail} />
    </SecondaryWindow>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Paper elevation={2} sx={{display: 'flex', justifyContent: 'space-between', padding: 1}}>
        <Box sx={{display: 'flex', gap: 1}}>
          <Button
            onClick={ () => handleMultipleApproval(selectedRows, 'denied')}
            variant='outlined'
            color='error'
            disabled={selectedRows.length === 0}
          >
            {translationList.deny}
          </Button>
          <Button
            onClick={ () => handleMultipleApproval(selectedRows, 'approved')}
            variant='outlined'
            color='success'
            disabled={selectedRows.length === 0}
          >
            {translationList.approve}
          </Button>
        </Box>
        <DateRange onRangeChange={ (t0, t1) => {
          setDateRange([t0, t1]);
          isLoading.current = true;
          }}
        />
      </Paper>

      <Loadable isLoading={isLoading.current}>
        <DataGrid
          localeText={localeText}
          autoHeight={true}
          rows={data}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[20, 50, 100]}
          density='compact'
          rowSelectionModel={ selectedRows }
          isRowSelectable={ param => param.row.approval.status.name === 'pending'}
          onRowSelectionModelChange={ selection => setSelectedRows(selection) }
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true},
              csvOptions: {
                disableToolbarButton: false,
                fileName: `Requests-${monthFromDaterange(dateRange)}.csv`,
              },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                approvalName: false,
                userFunctionNames: false,
                requestType: false,
              },
            },
            filter: {
              filterModel: {
                items: [{ field: 'approvalName', operator: 'contains', value: 'pending'}],
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          sx={{
            height: '100%',
            width: '100%',
          }}
        />
      </Loadable>
    </Box>
    </Fragment>
  );
}

export { TimeReview }
export default TimeReview;